import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

export default React.memo(function SEO({
  children,
  location,
  description,
  title,
  image,
}) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <Helmet
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
    >
      <html lang="en" />
      <title>{title}</title>
      {/* Fav icons */}
      <link rel="icon" type="image/svg+xml" href="/favicon.png" />
      <link rel="alternate icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"></link>
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/apple-icon-57x57.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/apple-icon-60x60.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/apple-icon-72x72.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/apple-icon-76x76.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      ></link>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      ></link>
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      ></link>
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      ></link>
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      ></link>
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      ></link>
      <link rel="manifest" href="/manifest.json"></link>

      {/* Meta tags */}
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="msapplication-TileColor" content="#129fb5" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#129fb5"></meta>
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || "/logo.png"} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta name="google-site-verification" content="ycoDdthb4X4lxD16zhfC8ruxBKDLAnGTjMXUN_qoaf8" />
      {children}
    </Helmet>
  )
})
